import request from '@/utils/request'

//列表
export function companyList(data) {
  return request({
    url: 'api/v1/companyList',
    method: 'post',
    data
  })
}
//新增修改
export function companySave(data) {
  return request({
    url: 'api/v1/companySave',
    method: 'post',
    data
  })
}
//作废发票
export function companyDel(data) {
  return request({
    url: 'api/v1/companyDel',
    method: 'post',
    data
  })
}

// 选择地区
export function disCode(data) {
  return request({
    url: 'api/v1/disCode',
    method: 'post',
    data
  })
}

//excel导入解析
export function importExcel(formdata) {
  return request({
    url: 'api/v1/importExcel',
    method: 'post',
    data: formdata,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}

//列表
export function getGsList(data) {
  return request({
    url: 'api/v1/jk/getGsList',
    method: 'post',
    data
  })
}

//重新发起
export function taskAgain(data) {
  return request({
    url: 'api/v1/taskAgain',
    method: 'post',
    data
  })
}


//再次回调
export function againCallback(data) {
  return request({
    url: 'api/v1/againCallback',
    method: 'post',
    data
  })
}

//重新识别
export function getTaskStatus(data) {
  return request({
    url: 'api/v1/getTaskStatus',
    method: 'post',
    data
  })
}

//任务检查list
export function taskListJc(data) {
  return request({
    url: 'api/v1/taskListJc',
    method: 'post',
    data
  })
}