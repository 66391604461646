<template>
	<div class="home">
		<!-- top -->
		<div>
			<div style="float: left;">
				<el-input size="mini" v-model="this.listQuery.name" style="width: 180px;" @keyup.enter="getList">
				</el-input>
				<el-button size="mini" type="primary" @click="getList">搜索</el-button>
				<el-select v-model="this.listQuery.taskName" class="m-2" filterable placeholder="选择任务类型" size="mini"
					@change="getList" clearable style="margin-left: 10px;width: 150px;">
					<el-option v-for="item in gsTypeOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
				<el-select v-model="this.listQuery.status" class="m-2" filterable placeholder="选择任务状态" size="mini"
					@change="getList" clearable style="margin-left: 10px;width: 150px;">
					<el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
				<el-select v-model="this.listQuery.businessStatus" class="m-2" filterable placeholder="选择任务结果"
					size="mini" @change="getList" clearable style="margin-left: 10px;width: 150px;">
					<el-option v-for="item in businessStatusOptions" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>
				<el-input size="mini" v-model="this.listQuery.log" placeholder="搜索错误原因" clearable @change="getList" style="margin-left:10px;width: 120px;" @keyup.enter="getList"></el-input>

				<el-date-picker v-model="listQuery.period" type="month" format="YYYYMM" value-format="YYYYMM"
					placeholder="请选择账期" size="mini" style="margin-left: 10px;width: 120px;" @change="getList()"
					:clearable="false"> </el-date-picker>
				<selectAddress v-model:address="listQuery.address" @success="getList()" style="margin-left:10px;width:120px"></selectAddress>

				<el-date-picker
					v-model="this.timetime"
					type="date"
					size="mini"
					placeholder="选择日期"
					style="width:130px;margin-left: 10px;"
					@change="changeDateTime"
					value-format="YYYY-MM-DD HH:mm:ss">
				</el-date-picker>

				<!-- <el-date-picker
					size="mini"
					v-model="listQuery.region"
					type="datetimerange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					style="width:312px;margin-left: 10px;"
					@change="getList()"
					value-format="YYYY-MM-DD HH:mm:ss">
				</el-date-picker> -->
			</div>
			<div style="text-align: right;margin-bottom: 10px;">
				<!-- <el-button size="mini" type="warning" @click="reDiscernAll">重新识别</el-button> -->
				<el-button size="mini" type="success" @click="handleUpdateAll">重新发起</el-button>
			</div>
		</div>

		<el-table size="mini" v-loading="isLoading" :data="tableData" border :height="contentStyleObj"
			style="width: 100%" :header-cell-style="{background:'#66b1ff',color:'#ffff'}"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
			<el-table-column label="机构名称" align="center" min-width="200px">
				<template #default="scope">
					<span>{{ scope.row.alias }}</span>
				</template>
			</el-table-column>
			<el-table-column label="企业名称" align="center" min-width="200px">
				<template #default="scope">
					<span>{{ scope.row.comName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="税号" align="center" min-width="160px">
				<template #default="scope">
					{{scope.row.taxNo}}
				</template>
			</el-table-column>
			<el-table-column label="任务类型" align="center" min-width="110px">
				<template #default="scope">
					{{filterTaskName(scope.row.taskName)}}
				</template>
			</el-table-column>
			<el-table-column label="任务状态" align="center" min-width="95px">
				<template #default="scope">
					<div class="item_icon">
						<!-- 排队中 0-->
						<p v-if="scope.row.status == '0'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 申报中 1 -->
						<p v-if="scope.row.status == '1'">
							<i class="el-icon-info"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 成功 2-->
						<p v-if="scope.row.status == '2'">
							<i class="el-icon-success"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>

						<!-- 失败 3 -->
						<p v-if="scope.row.status == '3'">
							<i class="el-icon-error"></i>
							<el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog"
								placement="top-start">
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</el-tooltip>
							<span v-else>
								<el-button size="mini" class="sbcg" style="border: 0;">
									{{filterStatus(scope.row.status)}}</el-button>
							</span>
						</p>
					</div>
				</template>
			</el-table-column>

			<el-table-column label="任务结果" align="left" min-width="280px">
				<template #default="scope">
					<div v-if="scope.row.status == '3' || scope.row.status == '2'"
						@click="open(scope.row, scope.$index)">
						<span v-if="scope.row.businessImg" class="demo-image__preview">
							<i class="el-icon-picture" v-if="scope.row.status == '3'"
								style="color:#f56c6c;font-size:16px;"></i>
							<i class="el-icon-picture" v-if="scope.row.status == '2'"
								style="color:#67c23a;font-size:16px;"></i>
						</span>

						<div class="item_icon">
							<!-- 成功 -->
							<p v-if="scope.row.businessStatus == '2'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbcg" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 申报超时失败 -->
							<p v-if="scope.row.businessStatus == '3'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbcg" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbcg" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 客户端启动失败 -->
							<p v-if="scope.row.businessStatus == '4'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 申报异常 -->
							<p v-if="scope.row.businessStatus == '5'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 申报失败 -->
							<p v-if="scope.row.businessStatus == '6'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 申报成功，待缴款 -->
							<p v-if="scope.row.businessStatus == '7'">{{$setUpStatusGsSb(scope.row.businessStatus)}}</p>

							<!-- 自动检查中 -->
							<p v-if="scope.row.businessStatus == '8'">{{$setUpStatusGsSb(scope.row.businessStatus)}}</p>

							<!-- 申报成功，无需缴款 -->
							<p v-if="scope.row.businessStatus == '10'">{{$setUpStatusGsSb(scope.row.businessStatus)}}
							</p>

							<!-- 扣款中 -->
							<p v-if="scope.row.businessStatus == '11'">{{$setUpStatusGsSb(scope.row.businessStatus)}}
							</p>

							<!-- 扣款异常 -->
							<p v-if="scope.row.businessStatus == '12'">
								<el-tooltip v-if="scope.row.businessLog" class="item" effect="dark"
									:content="scope.row.businessLog" placement="top-start">
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</el-tooltip>
								<span v-else>
									<el-button size="mini" class="sbyc" style="border: 0;">
										{{$setUpStatusGsSb(scope.row.businessStatus)}}</el-button>
								</span>
							</p>

							<!-- 申报成功，扣款成功 -->
							<p v-if="scope.row.businessStatus == '13'">{{$setUpStatusGsSb(scope.row.businessStatus)}}
							</p>

							<!-- 已申报过 -->
							<p v-if="scope.row.businessStatus == '14'">{{$setUpStatusGsSb(scope.row.businessStatus)}}</p>

							<p v-if="scope.row.businessStatus == '20'">{{$setUpStatusGsSb(scope.row.businessStatus)}}</p>

						</div>
					</div>
				</template>
			</el-table-column>

			<!-- <el-table-column label="标识符" align="center">
				<template #default="scope">
					{{scope.row.reqNo}}
				</template>
			</el-table-column>

			<el-table-column label="文件地址" align="center">
				<template #default="scope">
					{{scope.row.dataPath}}
				</template>
			</el-table-column>

			<el-table-column label="回调地址" align="center">
				<template #default="scope">
					{{scope.row.notifyUrl}}
				</template>
			</el-table-column> -->

			<el-table-column label="地区" align="center">
				<template #default="scope">
					{{ $cityFilter(scope.row.address)}}
				</template>
			</el-table-column>

			<el-table-column label="更新时间" align="center" min-width="120px">
				<template #default="scope">
					{{ $parseTime(scope.row.updatedAt,'{y}-{m}-{d} {h}:{i}')}}
				</template>
			</el-table-column>

			<el-table-column label="操作" align="center" min-width="110px">
				<template #default="scope">
					<el-popconfirm title="确认重新发起吗" @confirm="handleUpdate(scope.row)">
						<template #reference>
							<el-button size="mini" type="danger">重新发起</el-button>
						</template>
					</el-popconfirm>

					<!-- <el-popconfirm title="确认再次回调吗" @confirm="handleCallBack(scope.row.reqNo)">
						<template #reference>
							<el-button size="mini" type="primary">再次回调</el-button>
						</template>
					</el-popconfirm> -->

					<!-- <el-button size="mini" type="warning" @click="reDiscern(scope.row)">重新识别</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<sbImgUrl ref="sbImgUrl"></sbImgUrl>

		<!-- 导入 -->
	</div>
	<div class="pagination">
		<qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
			@pagination="getList" />
	</div>
</template>
  
<script>
import { getGsList, taskAgain, disCode, againCallback, getTaskStatus } from "@/api/personalTax";
import sbImgUrl from "./components/sbImgUrl.vue"
import selectAddress from '@/components/select/selectAddress';



export default {
	components: {
		sbImgUrl,
		selectAddress
	},
	data() {
		return {
			listQuery: {
				page: 1,
				limit: 20,
				period: this.$currentAccountPeriod(),
				region:[]

			},
			timetime:undefined,

			contentStyleObj: {},
			statusOptions: [
				{
					value: '0',
					label: '排队中',
				},
				{
					value: '1',
					label: '申报中',
				},
				{
					value: '2',
					label: '申报成功',
				},
				{
					value: '3',
					label: '申报失败',
				},
			],
			businessStatusOptions: [
				// {
				// 	value: '0',
				// 	label: '未申报',
				// },
				// {
				// 	value: '1',
				// 	label: '排队中',
				// },
				{
					value: '2',
					label: '成功',
				},
				{
					value: '3',
					label: '申报超时失败',
				},
				{
					value: '4',
					label: '客户端未启动失败',
				},
				{
					value: '5',
					label: '申报异常',
				},
				{
					value: '6',
					label: '申报失败',
				},
				{
					value: '7',
					label: '申报成功，待缴款',
				},
				{
					value: '8',
					label: '自动检查中',
				},
				{
					value: '10',
					label: '申报成功，无需缴款',
				},
				{
					value: '11',
					label: '扣款中',
				},
				{
					value: '12',
					label: '扣款异常',
				},
				{
					value: '13',
					label: '申报成功，扣款成功',
				},
				{
					value: '14',
					label: '已申报过',
				},
				{
					value: '20',
					label: '申报成功',
				},
			],
			gsTypeOptions: [
				{
					value: 'gs-sb-gs',
					label: '个税申报',
				},
				{
					value: 'gs-sb-gd',
					label: '个独申报',
				},
				{
					value: 'gs-kk-gs',
					label: '个税扣款',
				},
				{
					value: 'gs-kk-gd',
					label: '个独扣款',
				},
				{
					value: 'gs-cx',
					label: '撤销',
				},
				{
					value: 'gs-cj-ljsjcj',
					label: '累计数据采集',
				},
				{
					value: 'gs-cj-zxfjkccj',
					label: '专项附加扣除采集',
				},
				{
					value: 'gs-zf-gs',
					label: '作废个税',
				},
				{
					value: 'gs-zf-gd',
					label: '作废个独',
				},
				{
					value: 'gs-jc-gs',
					label: '检查个税',
				},
				{
					value: 'gs-jc-gd',
					label: '检查个独',
				},
			],
			total: 0,
			tableData: [],
			multipleSelection: [],
			dialogVisible: false,
			dialogVisibleUpdate: false,
			dialogFormVisibleImport: false,
			visible: false,
			isLoading: false,
			form: {
				name: '',
				loginType: '',
				taxNo: '',
				idNo: '',
				password: '',
				address: ''
			},
			updateForm: {
				name: '',
				loginType: '',
				taxNo: '',
				idNo: '',
				password: '',
				address: ''
			},
			value: [],
			options: [],
			loginTypeOptions: [
				{
					name: '申报密码登录',
				},
				{
					name: '实名信息登录',
				},
			],
			path: '',
			rules: {
				name: [
					{ required: true, message: '请输入企业名称', trigger: 'blur' },
				],
				loginType: [
					{ required: true, message: '请输入登录方式', trigger: 'blur' },
				],
				taxNo: [
					{ required: true, message: '请输入纳税人识别号', trigger: 'blur' },
				],
				idNo: [
					{ required: true, message: '请输入身份证号', trigger: 'blur' },
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
				],
				address: [
					{ required: true, message: '请选择地区', trigger: 'blur' },
				],
			}
		}
	},
	created() {
		this.getList()
		this.init()
		this.contentStyleObj = this.$getHeight(280)
	},
	methods: {
		filterTaskName(taskName) {
			var info = this.gsTypeOptions.filter(v => {
				if (taskName == v.value) {
					return v
				}
			})
			if (info.length == 1) {
				return info[0].label
			}
			return ""
		},
		// 获取list
		getList() {
			this.isLoading = true
			getGsList(this.listQuery).then(res => {
				this.isLoading = false
				this.total = res.data.data.total
				if (res.data.data.list) {
					this.tableData = res.data.data.list
				} else {
					this.tableData = []
				}

			})
		},
		init() {
			disCode({}).then(res => {
				if (res.data.msg == "success") {
					this.options = res.data.data.list
				}
			})
		},
		// 过滤
		filterStatus(status) {
			if (status == 0) {
				return '排队中'
			} else if (status == 1) {
				return '申报中'
			} else if (status == 2) {
				return '成功'
			} else if (status == 3) {
				return '失败'
			}
		},

		// 图
		open(row, index) {
			let imageAll = []
			this.tableData.map(res => {
				let url = {
					name: res.comName,
					url: ""
				}
				url.url = res.businessImg ? 'https://file.listensoft.net/' + res.businessImg + "?" + Math.random(10) : ""
				console.log(url);
				imageAll.push(url)
			})
			this.$refs.sbImgUrl.dialog(imageAll, index)
		},
		// 选中条数 
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//  重新发起
		handleUpdate(row) {
			taskAgain([row]).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '已重新发起',
						type: 'success'
					});
					this.getList()
				}
			})
		},
		// 批量重新发起
		handleUpdateAll() {
			if (this.multipleSelection.length == 0) {
				this.$message({
					message: "请选择",
					type: "warning"
				});
				return false;
			}
			this.$confirm('是否重新发起, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				taskAgain(this.multipleSelection).then(res => {
					if (res.data.msg == "success") {
						this.$message({
							message: '已重新发起',
							type: 'success'
						});
						this.getList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消重新发起'
				});
			});

		},
		// 再次回调
		handleCallBack(reqNo) {
			againCallback({ reqNo: reqNo }).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '发送成功',
						type: 'success'
					});
					this.getList()
				}
			})
		},
		// 重新识别
		reDiscern(row) {
			getTaskStatus({ reqNos: [row.reqNo] }).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '识别成功',
						type: 'success'
					});
					this.getList()
				}
			})
		},
		reDiscernAll() {
			if (this.multipleSelection.length == 0) {
				this.$message({
					message: "请选择",
					type: "warning"
				});
				return false;
			}
			let reqNoArr = []
			this.multipleSelection.map(v => {
				reqNoArr.push(v.reqNo)
			})
			getTaskStatus({ reqNos: reqNoArr }).then(res => {
				if (res.data.msg == "success") {
					this.$message({
						message: '识别成功',
						type: 'success'
					});
					this.getList()
				}
			})
		},
		// 选择地区
		handleChange(value) {
			console.log(value);
		},
		// 选择时间
		changeaaa(val){
			let date = new Date(val);
			date.setDate(date.getDate() + 1); //num正数为后一天，负数为前一天
			return date
		},
		changeDateTime(val){
			if(val){
				// 先把先前值拿下来  
				this.timetime = val 
				let timetimes =  this.changeaaa(val)
				let arrLast = this.$parseTime(timetimes,'{y}-{m}-{d} {h}:{i}:{s}')
				this.listQuery.region = [this.timetime,arrLast]
			}else{
				this.listQuery.region = []
			}
			this.getList()
		}
	},
	activated() {

	},
}
</script>
<style lang="scss">
.shubiao {
	cursor: pointer;
}
</style>
<style lang="scss" scoped>
.el-table {
	   th {
		padding: 0px;
	}

	   td {
		padding: 0px;
	}
}

.home {
	padding: 10px;

	.header {
		margin-bottom: 10px;

		.right {
			float: right;
		}

		.input-search {
			width: 180px;
			margin-right: 5px;
		}
	}

	.img-ewm {
		width: 50px;
	}
}

.pagination {
	text-align: right;
	margin-right: 26px;
	margin-top: 16px;
}

.el-input {
	width: 80%;
}

.item_icon {
	display: inline-block;
	cursor: pointer;

	i {
		display: inline-block;
		line-height: 28px;
		font-size: 16px;
	}

	.el-icon-error {
		color: #f56c6c;
	}

	.el-icon-success {
		color: #67c23a;
	}

	.el-icon-circle-check {
		color: #67c23a;
	}

	.el-icon-info {
		color: #409eff
	}

	.el-icon-warning {
		color: #e6a23c;
	}

	.el-icon-warning-outline {
		color: #e6a23c;
	}

	p {
		display: inline-block;
		font-size: 14px;
		line-height: 28px;
		color: #333;
	}
}
</style>